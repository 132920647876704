:root {
  --primary-color: #2720ff;
  --secondary-color: #f5233c;
  --background-color: #f8f9fa;
  --text-color: #333;
  --primary-text-color: #333;
  --secondary-text-color: #666;
  --secondary-bg-color: #f0f0f0;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-family: Inter, sans-serif;
  display: flex;
}

.container {
  box-sizing: border-box;
  flex-direction: column;
  width: 90%;
  max-width: 1200px;
  padding: 20px;
  display: flex;
}

.top-row {
  gap: 20px;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
}

.left-column, .right-column {
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 15px;
  flex: 1;
  padding: 20px;
  box-shadow: 0 2px 5px #0000001a;
}

.left-column {
  flex-direction: column;
  align-items: center;
  margin-right: 0;
  display: flex;
}

.right-column {
  flex: 1;
}

h2 {
  text-align: left;
}

#development-notice {
  color: red;
  text-align: center;
  margin-bottom: 10px;
  font-size: 1.2em;
  font-weight: bold;
  display: none;
}

#main-heading {
  text-align: center;
  margin: 0 auto 20px;
  width: fit-content;
  margin-block-start: .83em;
  display: block;
}

#recordButton {
  background-color: var(--primary-color);
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 15px;
  width: 100%;
  margin-bottom: 10px;
  padding: 20px;
  font-size: 18px;
  transition: background-color .3s;
}

#recordButton:hover {
  opacity: .9;
}

.red-button {
  background-color: var(--secondary-color) !important;
}

#sendPromptButton {
  background-color: var(--primary-color);
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  transition: background-color .2s;
}

#sendPromptButton:hover {
  opacity: .9;
}

.button-group {
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
  display: flex;
}

#copyButton, #copySessionButton {
  display: none;
}

.copy-btn {
  color: var(--text-color);
  cursor: pointer;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  margin: 5px;
  padding: 10px 12px;
  font-size: 14px;
  transition: all .2s;
  display: flex;
}

.copy-btn:hover {
  background-color: #e0e0f0;
}

.copy-btn .icon {
  font-size: 16px;
}

.copy-btn:active {
  background-color: #d0d0d0;
}

@media (width <= 1320px) and (width >= 768px) {
  .copy-btn {
    padding: 12px 20px;
  }

  .button-group {
    flex-flow: wrap;
    gap: 10px;
    display: flex;
  }
}

#timer {
  text-align: center;
  margin: 20px 0;
  font-size: 24px;
}

#progressBarContainer {
  background-color: #f0f0f0;
  border-radius: 5px;
  width: 100%;
  margin-top: 20px;
}

#progressBar {
  background-color: #0efb69;
  border-radius: 5px;
  width: 0%;
  height: 30px;
  transition: width .5s;
}

#status {
  text-align: center;
  min-height: 20px;
  margin-top: 10px;
}

#summary {
  white-space: pre-wrap;
}

#summary strong {
  font-weight: bold;
}

#summary h3 {
  margin-top: 1em;
  margin-bottom: 0;
  font-size: 1.17em;
  font-weight: bold;
}

#retryButton {
  margin: 10px auto;
  display: block;
}

.prompt-section {
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 15px;
  width: 100%;
  margin-top: 0;
  padding: 20px;
  box-shadow: 0 2px 5px #0000001a;
}

.prompt-container {
  align-items: stretch;
  gap: 1rem;
  margin-bottom: 1rem;
  display: flex;
}

.prompt-container textarea {
  resize: none;
  border: 1px solid #ccc;
  border-radius: 10px;
  flex: 1;
  height: 60px;
  padding: .5rem;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
  font-size: 16px;
  line-height: 1.5;
}

.prompt-container button {
  background-color: var(--primary-color);
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 12px;
  min-width: 120px;
  padding: .5rem 1rem;
  font-size: 16px;
}

.prompt-container button:hover {
  background-color: #1f19cc;
}

.prompt-container button:disabled {
  cursor: not-allowed;
  background-color: #ccc;
}

.prompt-responses {
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 1rem;
  display: flex;
}

.prompt-response {
  background-color: var(--secondary-bg-color);
  border-radius: 8px;
  padding: 1.25rem;
  box-shadow: 0 2px 4px #0000001a;
}

.prompt-question {
  color: var(--secondary-text-color);
  align-items: center;
  gap: .5rem;
  margin-bottom: .75rem;
  font-size: 16px;
  font-weight: 500;
  display: flex;
}

.prompt-question:before {
  content: "Q:";
  color: #000;
  font-weight: 600;
}

.prompt-answer {
  color: var(--primary-text-color);
  white-space: pre-wrap;
  padding-left: .5rem;
  font-size: 16px;
  line-height: 1.6;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  border: 2px solid #ffffff4d;
  border-top-color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: 0 auto;
  animation: .8s linear infinite spin;
  display: inline-block;
}

.send-button-content {
  justify-content: center;
  align-items: center;
  gap: 8px;
  min-width: 60px;
  min-height: 24px;
  display: flex;
}

.audio-source-select {
  border: 1px solid var(--border-color);
  background-color: var(--background-color);
  color: var(--text-color);
  cursor: pointer;
  border-radius: 4px;
  width: 100%;
  max-width: 300px;
  margin: 10px 0;
  padding: 8px 12px;
  font-size: 14px;
}

.audio-source-select:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(var(--primary-color-rgb), .2);
  outline: none;
}

.audio-visualizer-container {
  background-color: #0000;
  width: 60%;
  height: 24px;
  margin: 10px 0;
  overflow: hidden;
}

#audioVisualizer {
  width: 100%;
  height: 100%;
  display: block;
}

.browser-warning-overlay {
  z-index: 999;
  background: #00000080;
  position: fixed;
  inset: 0;
}

.browser-warning-popup {
  z-index: 1000;
  text-align: center;
  background: #fff;
  border-radius: 8px;
  max-width: 400px;
  padding: 40px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 2px 10px #0000001a;
}

.browser-warning-popup p {
  margin-bottom: 30px;
  line-height: 1.5;
}

.browser-warning-popup button {
  background: var(--primary-color);
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  padding: 12px 24px;
  font-size: 16px;
  transition: background-color .2s;
}

.browser-warning-popup button:hover {
  background: var(--primary-color);
  opacity: .9;
}

@media (width <= 768px) {
  .container {
    width: 95%;
  }

  .top-row {
    flex-direction: column;
  }

  .left-column, .right-column {
    width: 100%;
  }

  #summary {
    font-size: 14px;
  }

  .prompt-container {
    flex-direction: column;
  }

  #sendPromptButton {
    width: 100%;
    margin-top: 10px;
  }

  .copy-btn {
    white-space: nowrap;
    padding: 8px 16px;
    font-size: 14px;
  }

  .button-group {
    flex-wrap: wrap;
  }
}

.summary-header {
  align-items: center;
  margin-bottom: 15px;
  display: flex;
}

.summary-header h2 {
  margin: 0;
}

.new-meeting-link {
  color: var(--text-color);
  cursor: pointer;
  align-items: center;
  gap: 8px;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: bold;
  display: inline-flex;
}

.new-meeting-link:hover {
  opacity: .7;
}

.new-meeting-link .icon {
  align-items: center;
  font-size: 16px;
  line-height: 1;
  display: inline-flex;
}

.new-meeting-link .text {
  align-items: center;
  text-decoration: underline;
  display: inline-flex;
}
/*# sourceMappingURL=index.660dffd3.css.map */
