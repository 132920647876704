:root {
    --primary-color: #2720ff;
    --secondary-color: #f5233c;
    --background-color: #f8f9fa;
    --text-color: #333;
    --primary-text-color: #333;
    --secondary-text-color: #666;
    --secondary-bg-color: #f0f0f0;
}

body {
    font-family: 'Inter', sans-serif;
    margin: 0;
    padding: 0;
    background-color: var(--background-color);
    color: var(--text-color);
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.container {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    width: 90%;
    padding: 20px;
    box-sizing: border-box;
}

.top-row {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    gap: 20px;
}

.left-column, .right-column {
    flex: 1;
    padding: 20px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
}

.left-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 0;
}

.right-column {
    flex: 1;
}

h2 {
    text-align: left;
}

#development-notice {
    color: red;
    font-weight: bold;
    font-size: 1.2em;
    text-align: center;
    margin-bottom: 10px;
    display:none;
}

#main-heading {
    text-align: center;
    display: block;
    margin: 0 auto 20px; /* Added bottom margin */
    width: fit-content; /* Ensures the element only takes up as much width as needed */
    margin-block-start: 0.83em;
}

/* Record button styles */
#recordButton {
    width: 100%;
    padding: 20px;
    font-size: 18px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-bottom: 10px;;
}

#recordButton:hover {
    opacity: 0.9;
}

.red-button {
    background-color: var(--secondary-color) !important;
}

/* Send button styles */
#sendPromptButton {
    padding: 10px 20px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.2s;
}

#sendPromptButton:hover {
    opacity: 0.9;
}

/* Copy buttons group */
.button-group {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
    align-items: center;
}

/* Copy buttons specific styles */
#copyButton, #copySessionButton {
    display: none;
}

.copy-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 12px;
    margin: 5px;
    background-color: #f0f0f0;
    color: var(--text-color);
    border: 1px solid #ddd;
    border-radius: 10px;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.2s ease;
}

.copy-btn:hover {
    background-color: #e0e0f0;
}

.copy-btn .icon {
    font-size: 16px;
}

.copy-btn:active {
    background-color: #d0d0d0;
}


/* Medium breakpoint for tablets/iPads */
@media (max-width: 1320px) and (min-width: 768px) {
    .copy-btn {
        padding: 12px 20px;
    }
    
    .button-group {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
    }
}

#timer {
    font-size: 24px;
    text-align: center;
    margin: 20px 0;
}

#progressBarContainer {
    width: 100%;
    background-color: #f0f0f0;
    border-radius: 5px;
    margin-top: 20px;
}

#progressBar {
    width: 0%;
    height: 30px;
    background-color: #0efb69;
    border-radius: 5px;
    transition: width 0.5s;
}

#status {
    margin-top: 10px;
    text-align: center;
    min-height: 20px; /* Adjust this value as needed */
}

#summary {
white-space: pre-wrap;
}

#summary strong {
    font-weight: bold;
}

#summary h3 {
    font-size: 1.17em;
    font-weight: bold;
    margin-top: 1em;
    margin-bottom: 0;
}

#retryButton {
    margin: 10px auto;
    display: block;
}

/* Prompt Section Styles */
.prompt-section {
    width: 100%;
    margin-top: 0;
    padding: 20px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
}

.prompt-container {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    align-items: stretch;  
}

.prompt-container textarea {
    flex: 1;
    height: 60px;  
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 10px;
    resize: none;
    font-size: 16px;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    line-height: 1.5;
}

.prompt-container button {
    padding: 0.5rem 1rem;
    background-color: var(--primary-color);
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    min-width: 120px;  /* Increased from 80px */
    border-radius: 12px;
}

.prompt-container button:hover {
    background-color: #1f19cc;
}

.prompt-container button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.prompt-responses {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-top: 1rem;
}


.prompt-response {
    background-color: var(--secondary-bg-color);
    border-radius: 8px;
    padding: 1.25rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.prompt-question {
    font-size: 16px;
    color: var(--secondary-text-color);
    margin-bottom: 0.75rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.prompt-question::before {
    content: "Q:";
    font-weight: 600;
    color: black;
}

.prompt-answer {
    font-size: 16px;
    line-height: 1.6;
    color: var(--primary-text-color);
    white-space: pre-wrap;
    padding-left: 0.5rem;
}

/* Spinner Animation */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.spinner {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 0.8s linear infinite;
    margin: 0 auto;
}

.send-button-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    min-width: 60px;  /* Prevent button size from changing */
    min-height: 24px; /* Prevent button size from changing */
}

.audio-source-select {
    width: 100%;
    max-width: 300px;
    padding: 8px 12px;
    margin: 10px 0;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    background-color: var(--background-color);
    color: var(--text-color);
    font-size: 14px;
    cursor: pointer;
}

.audio-source-select:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 2px rgba(var(--primary-color-rgb), 0.2);
}

.audio-visualizer-container {
    width: 60%;
    height: 24px;
    margin: 10px 0;
    overflow: hidden;
    background-color: transparent;
}

#audioVisualizer {
    width: 100%;
    height: 100%;
    display: block;
}

/* Browser warning popup styles */
.browser-warning-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.browser-warning-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    max-width: 400px;
    text-align: center;
}

.browser-warning-popup p {
    margin-bottom: 30px;
    line-height: 1.5;
}

.browser-warning-popup button {
    background: var(--primary-color);
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.2s;
}

.browser-warning-popup button:hover {
    background: var(--primary-color);
    opacity: 0.9;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
    .container {
        width: 95%;
    }

    .top-row {
        flex-direction: column;
    }

    .left-column, .right-column {
        width: 100%;
    }

    #summary {
        font-size: 14px;
    }

    .prompt-container {
        flex-direction: column;
    }

    #sendPromptButton {
        width: 100%;
        margin-top: 10px;
    }

    .copy-btn {
        padding: 8px 16px;
        font-size: 14px;
        white-space: nowrap;
    }

    .button-group {
        flex-wrap: wrap;
    }
}

.summary-header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.summary-header h2 {
    margin: 0;
}

.new-meeting-link {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 20px;
    color: var(--text-color);
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
}

.new-meeting-link:hover {
    opacity: 0.7;
}

.new-meeting-link .icon {
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    line-height: 1;
}

.new-meeting-link .text {
    display: inline-flex;
    align-items: center;
    text-decoration: underline;
}
